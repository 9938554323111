
.statistics-overview .list .product-item {
    background-color: var(--color-light-500);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
}

.statistics-overview .product-item .reports {
    min-width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px 12px;
    background-color: var(--color-orange-500);
}

.statistics-overview .product-item:not(:only-child) {
    border-bottom: 1px solid var(--color-orange-100);
}

.statistics-overview .list-row .btn {
    color: var(--color-light-100);
    padding: 20px;
}

@media only screen and (min-width: 768px) {
    .statistics-overview .list .product-item {
        min-width: 460px;
    }
}
