.checkbox-wrapper {
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 6px 0px;
    cursor: pointer;
}

.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
}

.checkbox-wrapper .box {
  position: relative;
  display: inline-block;
  height: 18px;
  width: 18px;
  background: var(--color-light-500);
  margin-right: 4px;
  flex-shrink: 0;
}

.checkbox-wrapper.active .box {
  box-shadow: inset 0 0 0 2px var(--color-orange-500);
}

.checkbox-wrapper svg {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.checkbox-wrapper.active svg {
  opacity: 1;
}
