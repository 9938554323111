.statistics-overview .content .disclaimer {
    color: var(--color-light-100);
    display: flex;
    gap: 8px;
    padding: 8px 0px;
}

@media only screen and (min-width: 768px) {
    .statistics-overview .disclaimer svg {
        width: 10px;
        height: 10px;
        margin-top: 2px;
    }
}