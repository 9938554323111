.content-header-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;

}

.content-header-icon-wrapper {
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark-500);
}

.content-header svg {
    height: 24px;
}

.content-header {
    color: var(--color-dark-500);
}