.list-view {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.list-view .nearby-reports {
    padding-top: 20px;
    background-color: var(--color-light-500);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.list-view .nearby-reports .list {
    padding: 20px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.list-view .list-view-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-view .list-view-button .btn {
    width: fit-content;
}

.list-view .cta-container {
    padding: 20px 0px 40px 0;
    background-color: var(--color-background);
}

@media only screen and (min-width: 768px) {
    .list-view .list-view {
        align-items: center;
    }

    .list-view .slider-wrapper {
        width: calc(100vw - 488px);
    }

    .list-view .recent-reports {
        padding: 0px 244px;
    }

    .list-view .search-bar {
        padding: 20px 244px;
    }
    
    .list-view .nearby-reports {
        padding: 40px 244px;
        width: calc(100vw - 488px);
    }

    .list-view .list-view-button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .list-view .nearby-reports .list {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 16px 32px; 
    }

    .list-view .list-view-button {
        align-self: center;
    }

    .list-view .cta-container {
        background-color: var(--color-light-500);
    }
}
