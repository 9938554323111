.mobile-navigation {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.mobile-navigation .wrapper {
    background-color: var(--color-orange-500);
    display: flex;
    flex-direction: column;
    padding: 35px;
    padding-top: 55px;
    flex-grow: 6;
    justify-content: space-between;
}

.mobile-navigation .wrapper .close-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.mobile-navigation .links {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.mobile-navigation .logo {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 30px;
    flex-grow: 5;
}

.mobile-navigation .logo svg {
    width: 185px;
}
