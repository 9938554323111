.header {
    display: flex;
    justify-content: space-between;
    padding: 40px 24px;
    padding-bottom: 16px;
    background-color: var(--color-light-500);
    z-index: 1000;
}

.header .header-logo {
    height: 32px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.header .hamburger-menu {
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .hamburger-menu .icon {
    width: 16px;
}

.header-back-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 20px;
}

.header-back-wrapper .btn {
    width: fit-content;
    gap: 0px;
}

 .back-btn {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
} 

.back-btn .back-btn-icon {
    height: 18px;
    margin-bottom: 3px;
}

.header .navigation-links {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: center;
    justify-content: flex-end;
}

.header .navigation-links a {
    white-space: nowrap;
    padding: 11px 16px;
}

.header .navigation-links .button {
    background-color: var(--color-orange-500);
    display: flex;
    gap: 10px;
}

.header-wrapper .tinted {
    color: var(--color-dark-500);
    background-color: var(--color-orange-500);
}

@media only screen and (min-width: 768px) {
    .back-btn {
        padding-left: 244px;
        padding-right: 244px;
    }
}
