.map-container {
  flex: 1;
  display: flex;
  position: relative;
}

.google-map {
  flex: 1;
}

.google-map .marker {
  display: inline-block;
  box-sizing: border-box;

  padding: 6px 13px;
  border-radius: 25px;

  font-size: 18px;
  font-family: sans-serif;
  font-weight: 900;
  color: #fff;
  background-color: #000;
  border: 2px solid #fff;

  transform: translate(-50%, -50%);
}

.google-map .marker.active {
  background-color: var(--color-orange-500);
  color: var(--color-dark-500);
}

.map-container .report-results {
  pointer-events: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.map-container .report-results .btn-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0px 24px;
  gap: 24px;
}

.map-container .point-of-sale-label {
  background-color: var(--color-orange-500);
  padding: 8px 16px;
  min-width: 160px;
  text-align: center;
}

.map-container .report-results .btn {
  pointer-events: all;
  width: fit-content;
  gap: 0;
  padding: 10px;
}

.map-container .report-results .report {
  pointer-events: all;
}

.map-container .search-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.map-container .report-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  left: 20px;
}

.map-container .map-center-button {
  position: absolute;
  bottom: 120px;
  right: 20px;

  background-color: var(--color-light-500);
  width: 56px;
  height: 56px;
  border-radius: 26px;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: #e0e0e0 0px 2px 5px 0;

  cursor: pointer;
}


@media only screen and (min-width: 768px) {

  .map-container .report-button {
    display: none;
  }

  .map-container .search-bar {
    padding: 20px 244px;
  }
  
  .map-container .report-results .btn-container {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .map-container .point-of-sale-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
  }
}
