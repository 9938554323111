.image-viewer {
    display: flex;
    flex-direction: column;
}

.image-viewer .viewer img {
    width: 100%;
}

.image-viewer .preview-list {
    padding: 16px 20px;
    display: flex;
    overflow: scroll;
    overflow-y: hidden;
    gap: 8px;
}

.image-viewer .preview {
    cursor: pointer;
}

.image-viewer .preview img {
    height: 80px;
    width: 80px;
    
    object-fit: cover;
}

@media only screen and (min-width: 768px) {
    .image-viewer {
        max-width: 600px;
        min-width: 200px;
    }

    .image-viewer .preview img {
        height: 92px;
        width: 92px;
    }
}
