.input-wrapper .suggestions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    transform: translateY(100%);
    z-index: 1000;
    box-shadow: #e0e0e0 0px 2px 5px 0;
}

.input-wrapper .suggestions.hidden {
    display: none;
}

.input-wrapper .suggestions .footer {
    box-shadow: 0 -13px 0 -12px var(--color-light-400);
    padding: 8px 16px;
}

.input-wrapper .suggestions .suggestion {
    padding: 12px 16px;
    background-color: var(--color-light-500);

    display: flex;
    gap: 16px;
    align-items: center;
}

.input-wrapper .suggestions .focused {
    background-color: var(--color-light-400);
}

.input-wrapper .suggestions .label {
    display: flex;
    flex-direction: column;
}

.input-wrapper .suggestions img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
}