.card {
    width: 100%;
    background-color: var(--color-background);
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.card-image {
    height: 114px;
    width: 110px;
    object-fit: cover;
}

.card-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    text-overflow: ellipsis;
}

.card-title {
    text-overflow: ellipsis;
    width: 50vw;
    white-space: nowrap;
    overflow: hidden;
    color: var(--color-dark-500);
    font-size: 1.25rem;
    font-weight: 900;
    padding: 0;
}

.card-info {
    text-overflow: ellipsis;
    font-size: 1rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.card-info-item {
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 2px;
    align-items: center;
    color: var(--color-dark-200);
}

.card-info-item-icon {
    height: 14px;
    color: var(--color-orange-500);
}
