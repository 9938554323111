.add-report {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.add-report .qm-attribution {
  margin-top: 6px;
}

.add-report .qm-attribution a {
  text-decoration: underline;
  font-weight: 700;
}

.add-report h2 {
  margin-top: 0;
  margin-bottom: 8px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-title-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.info-title-icon-wrapper {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(
    --color-orange-500
  ); /* TODO: CHANGE COLOR TO DARK-500 */
}

.info-title-icon {
  height: 24px;
}

.info-title {
  color: var(--color-dark-500);
}

.info-description {
  font-size: 1rem;
  padding: 0px 20px;
}

.illustration {
  height: 80px;
  margin: auto;
}

.form {
  background-color: var(--color-orange-100);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.overlay-container {
  position: relative;
}

.scanButton-container {
  display: flex;
  gap: 16px;
}
.scanButton-container .input-wrapper {
  flex-grow: 1;
}
.fw-select {
  flex-grow: 1;
  border: none;
}

.fw-select input {
  box-shadow: none;
}

.scanButton-container .scanButton,
.scanButton-container .clearButton {
  flex-basis: 44px;
  flex-shrink: 0;
  background-color: var(--color-orange-500);

  padding: 10px 14px;
  box-sizing: border-box;

  cursor: pointer;
}

.required-label {
  margin-left: 4px;
}

.file-upload-wrapper {
  border-color: var(--color-orange-500);
  border-style: dashed;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.file-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.upload-button {
  padding: 8px;
  width: fit-content;
}

.add-report .preview-carousel {
  height: 100px;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  gap: 20px;
}

.add-report .preview-carousel:empty {
  height: 0;
}

.add-report .preview-carousel img {
  cursor: not-allowed;
}

.add-report .privacy-link {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .add-report {
    padding-left: 244px;
    padding-right: 244px;
    padding-top: 40px;
  }

  .add-report .btn {
    float: right;
    max-width: 350px;
  }
}
