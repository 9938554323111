
.report-carousel {
    padding: 24px;
  
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  
  .report-carousel .report {
    flex-shrink: 0;
    width: 280px;
    height: 260px;
    margin-right: 12px;
    cursor: pointer;
  
    background-color: #fff;
  
    display: flex;
    flex-direction: column;
    
    box-shadow: #e0e0e0  0px 2px 5px 0;
  }
  
  .report-carousel .report img {
    flex-grow: 1;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
  
  .report-carousel .report .name,
  .report-carousel .report .bottom {
    margin: 8px 16px;
  }
  
  .report-carousel .report .name {
    font-size: 18px;
    font-weight: 900;
    margin-top: 16px;
    text-overflow: ellipsis;
  }
  
  .report-carousel .report .bottom {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 450;
    color: #696969;
  
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  
  .report-carousel .report .bottom svg {
    margin: 3px 4px 3px 0;
    vertical-align: bottom;
  }

  .report-carousel .bottom .manufacturer, .report-carousel .place {
    max-width: 73px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .report-carousel .bottom .date {
    white-space: nowrap;
  }
