.thank-you-page .thank-you-message {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    text-align: center;
}

.thank-you-page .cta-container {
    background-color: #fff;
    padding-top: 40px;
}


@media only screen and (min-width: 768px) {
    .thank-you-page .cta-container {
        background-color: var(--color-light-500);
        padding: 40px 244px;
    }
}
