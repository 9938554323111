.scanner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: var(--color-dark-500);
    --color-overlay: rgba(0, 0, 0, 0.5);
    --color-scanline: var(--color-red-300);
    --color-frame: rgba(255, 255, 255, 0.6);
}

.scanner.done {
    --color-overlay: rgba(0, 64, 0, 0.5);
    --color-scanline: var(--color-green-300);
}

.scanner video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 100;
}

.scanner .overlay {
    position: absolute;
    width: 100%;
    height: 100%;

    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.scanner .overlay .top {
    flex-basis: 100%;
    background-color: var(--color-overlay);

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: var(--color-light-500);
    text-align: center;
}

.scanner .overlay .top h2 {
    margin-bottom: 10px;
}

.scanner .overlay .top > * {
    margin: 0 10vmin;
}

.scanner .overlay .center {
    flex-shrink: 0;
    display: flex;
}

.scanner .overlay .center .frame {
    flex-basis: 80vmin;
    height: 48vmin;
    margin: 1px;
    box-shadow: 0 0 0 1px var(--color-frame);
}

.scanner .overlay .center .frame::before {
    content: '';
    display: block;
    background-color: var(--color-scanline);
    height: 1px;
    margin-top: 24vmin;
}

.scanner .overlay .center::before,
.scanner .overlay .center::after {
    content: '';
    display: block;
    flex-grow: 1;
    background-color: var(--color-overlay);
}

.scanner .overlay .bottom {
    flex-basis: 100%;
    background-color: var(--color-overlay);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.scanner .overlay .button-container {
    margin: 16px;
}
