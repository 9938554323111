.loader-container {
    display: flex;
    width: -webkit-fill-available;
    padding: 40px;
    justify-content: center;
    height: 30vh;
}

.loader-container .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loader-container .non-overlay {
    display: contents;
}

.loader-container .loader {
    -webkit-animation:spin 1s steps(8) infinite;
    -moz-animation:spin 1s steps(8) infinite;
    animation:spin 1s steps(8) infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
