.lightbox {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;
}

.lightbox img {
    width: 80vmin;
    height: 80vmin;
    object-fit: contain;
    object-position: center;
}

.lightbox .btn {
    position: absolute;
    width: fit-content;
    gap: 0;
    padding: 12px;
    top: 10px;
    right: 10px;
}