@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@400;900&display=swap');

:root {
  --color-orange-100: #FDF1EA;
  --color-orange-500: #F5A400;
  --color-dark-100: #6c6c6c;
  --color-dark-200: #545454;
  --color-dark-300: #3b3b3b;
  --color-dark-400: #232323;
  --color-dark-500: #1a1a1a;
  --color-light-100: #9d9d9d;
  --color-light-200: #b6b6b6;
  --color-light-300: #cecece;
  --color-light-400: #f3f3f3;
  --color-light-500: #fff;
  --color-red-100: #ff4c97;
  --color-red-200: #fa0068;
  --color-red-300: #d10057;
  --color-blue-100: #58c0ff;
  --color-blue-200: #07a2ff;
  --color-blue-300: #008add;
  --color-green-100: #ecfccb;
  --color-green-200: #bef264;
  --color-green-300: #84cc16;
  --color-background: #FAFAFE;
}

.foodwatch {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100%;
  
  font-size: 16px;
  font-family: 'Albert Sans', sans-serif;
  background-color: var(--color-background);
}

.text-2xl-black, .text-xl-black, .text-lg-black, .text-base-black, .text-sm-black, .text-xs-black {
  font-weight: 900;
}

.text-lg, .text-base, .text-sm, .text-xs {
  font-weight: 450;
}

.text-2xl-black {
  line-height: 32px;
  font-size: 24px;
}
.text-xl-black {
  line-height: 28px;
  font-size: 20px;
}
.text-lg-black, .text-lg {
  line-height: 26px;
  font-size: 18px;
}
.text-base-black, .text-base {
  line-height: 22px;
  font-size: 16px;
}
.text-sm-black, .text-sm {
  line-height: 20px;
  font-size: 14px;
}

.text-xs-black, .text-xs {
  line-height: 16px;
  font-size: 12px;
}

.button-icon {
  height: 24px;
  width: 24px;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  height: 50vh;
}

@media only screen and (min-width: 768px) {
  .error {
    padding: 40px 224px;
  }
}
