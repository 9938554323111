.statistics-overview .statistic-header {
    background-color: var(--color-orange-500);
    padding: 24px 20px;
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.statistics-overview .statistic-header .logo {
    border-radius: 100px;
    height: 54px;
    width: 54px;
    overflow: hidden;
    background-color: var(--color-light-500);
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.statistics-overview .statistic-header .logo img {
    width: 100%;
}

.statistics-overview .content .row {
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    gap: 8px,
}

.statistics-overview .content .title {
    margin-bottom: 8px;
}

.statistics-overview .error {
    display: flex;
    padding: 32px 20px;
    height: 100vh;
}

.statistics-overview .cta-card {
    margin-top: 52px;
}

@media only screen and (min-width: 768px) {
    .statistics-overview .statistic-header, .statistics-overview .content .row {
        padding-left: 244px;
        padding-right: 244px;
    }

    .statistics-overview .cta-container {
        margin: 40px 244px;
    }
}
