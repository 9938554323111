.statistics {
    display: flex;
    flex-direction: column;
}

.statistics .header {
    display: flex;
    flex-direction: column;
    padding: 0px;
    background-color: var(--color-background-500);
}

.statistics .form {
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 12px;
}

.statistics .list {
    background-color: var(--color-background);
    padding-bottom: 55px;
}

.statistics .list .item {
    background-color: var(--color-light-500);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    gap: 12px;
}

.statistics .list .item:nth-child(even) {
    background-color: var(--color-orange-100);
}

.statistics .list .item:nth-child(odd) {
    background-color: var(--color-light-500);
}

.statistics .item .logo {
    border-radius: 100px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    background-color: var(--color-light-500);
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.statistics .item .logo img {
    width: 100%;
}

.statistics .item .name {
    flex-grow: 3;
}

.statistics .item .reports {
    min-width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px 12px;
    background-color: var(--color-orange-500);
}

@media only screen and (min-width: 768px) {
    .statistics {
        padding-top: 40px;
    }
    .statistics .header {
        padding: 0px 244px;
    }
    .statistics .form {
        max-width: 480px;
        padding: 20px 0px;
    }

    .statistics .list {
        padding: 0px 244px;
        background-color: var(--color-light-500);
    }

    .statistics .item {
        cursor: pointer;
    }
}
