.cta-card {
    position: relative;
    box-sizing: border-box;
    margin: auto;
    
    width: 366px;
    height: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 44px 0;
    gap: 24px;

    background-image: url(./assets/cta-card-border.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    
    text-align: center;
}

.share .cta-card {
    gap: 16px;
}

.cta-card-icon {
    position: absolute;
    top: 18px;
    left: 8px;

    background-color: var(--color-orange-500);
    height: 70px;
    width: 70px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.cta-card-icon svg {
    height: 42px;
    width: 42px;
}

@media only screen and (min-width: 768px) {
    .cta-container {
        display: flex;
        justify-content: flex-start;
    }

    .cta-card {
        margin: 0;
    }
}
