.search-bar {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.search-bar input {
  flex: 1;
  height: 44px;
  min-width: 200px;
  box-sizing: border-box;

  padding: 12px 10px 8px 48px;
  font-size: 16px;
  font-weight: 450;
  border: none;

  box-shadow: #e0e0e0  0px 2px 5px 0;
}

.search-bar .input-wrapper {
  flex-grow: 1;
}

.search-bar .search-icon {
  position: absolute;
  margin: 10px;
  z-index: 1;
}

.search-bar .clear-button {
  padding: 14px 12px 10px 12px;
  z-index: 1;
  margin-left: -40px;
  cursor: pointer;
}

.search-bar input::placeholder {
  color: var(--color-light-100);
}

.search-bar .switch-view-button {
  background-color: var(--color-orange-500);
  width: 44px;
  height: 44px;
  margin-left: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}