.toast {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  background-color: var(--color-orange-100);
  z-index: 1000;

  display: flex;
  align-items: stretch;
}

.toast > *:last-child {
  padding-right: 20px;
}

.toast > *:last-child {
  padding-left: 20px;
}

.toast > * {
  padding: 20px 8px;
  display: flex;
  align-items: center;

  flex: 0;
}

.toast .text {
  flex: 1;
}

.toast .btn {
  gap: 0;
  padding: 10px;
}
