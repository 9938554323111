.footer {
    background-color: var(--color-light-500);
}

.footer-logo-wrapper {
    padding-top: 60px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
}

.footer-logo {
    width: 200px;
}

.footer-links-wrapper {
    background-color: var(--color-orange-500);
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 70px 20px;
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

a {
    color: var(--color-dark-400);
    text-decoration: none;
}

@media only screen and (min-width: 768px) {
    .footer-logo-wrapper, .footer-links-wrapper {
        padding-left: 244px;
        padding-right: 244px;
    }

    .footer-logo-wrapper {
        justify-content: flex-start;
    }

    .bottom-links {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}
