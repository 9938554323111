.bar-graph {
    background-color: var(--color-light-500);
    padding: 24px;
}

.bar-graph .item-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 0px;
}

.bar-graph .label {
    color: var(--color-light-200)
}

.bar-graph .bar-container {
 display: flex;
 flex-direction: row;
 align-items: center;
 gap: 12px;
}

.bar-graph .bar {
    background-color: var(--color-orange-500);
    height: 35px;
    min-width: 6px;
}

.bar-graph .amount {
    color: var(--color-black-500);
}
