.installer-toast {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    
    background-color: var(--color-orange-100);
    z-index: 1000;
    
    display: flex;
    padding: 20px;
    gap: 16px;
}

.installer-toast .btn {
    width: fit-content;
    gap: 0;
    padding: 10px;
}

.installer-toast .text {
    flex-grow: 3;
    align-self: center;
}

.installer-toast .button-container {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    flex-grow: 3;
}

.installer-pop-up {
    background-color: var(--color-light-500);
    width: -webkit-fill-available;
    position: fixed;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    padding: 20px 0px;
    z-index: 1000;
    gap: 36px;
    bottom: 0;
}

.installer-pop-up .instructions {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap:8px;
}

.installer-pop-up .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.installer-pop-up .installer-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.installer-pop-up .close-btn {
    padding: 0px 20px
}
