.btn {
   padding: 15px 20px;
   border: none;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap: 10px;
   width: 100%;
   font-size: 1rem;
   font-weight: 900;
   cursor: pointer;
}

.btn svg {
   margin-bottom: -4px;
}

.btn-primary {
   color:  var(--color-dark-500); 
   background-color: var(--color-orange-500); 
}

.btn-secondary {
   color: var(--color-orange-500);
   background-color: var(--color-dark-500); 
}

.btn-tertiary {
   color: var(--color-orange-500);
   background-color: transparent; 
   padding: 0px;
}