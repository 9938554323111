.report-overview .row {
  padding: 12px 20px;
}

.report-overview .product-name {
  margin-bottom: 8px;
}

.report-overview .info-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.report-overview .info-column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.report-overview .info-item-icon {
  margin-right: 4px;
}

.report-overview .info-item {
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #696969;
}

.report-overview .reports-label {
  background-color: var(--color-orange-500);
  display: flex;
  padding: 8.5px 12px;
  gap: 8px;
  min-width: 120px;
  align-items: center;
  justify-content: center;
  height: min-content;
}

.report-overview .reporter {
  background-color: var(--color-orange-100);
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.report-overview .statistic-row .item-container {
  background-color: var(--color-light-500);
}

.report-overview .statistic-row .item-info {
  background-color: var(--color-orange-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
}

.report-overview .statistic-row .logo {
  border-radius: 100px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  background-color: var(--color-light-500);
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.report-overview .statistic-row img {
  width: 100%;
}

.report-overview .statistic-row .name {
  flex-grow: 3;
}

.report-overview .statistic-row .reports {
  min-width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px 12px;
  background-color: var(--color-orange-500);
}

.report-overview .item-container a {
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  gap: 8px;
  color: var(--color-orange-500);
}

.report-overview .item-container a svg {
  width: 20px;
  height: 20px;
}

.report-overview .statistic-content {
  padding: 12px 16px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.report-overview .statistic-row {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.report-overview .no-horizontal-padding {
  padding: 20px 0px;
}

.report-overview .cta-container {
  padding: 12px 0;
}

.field-error {
  color: var(--color-red-300);
  padding: 12px 0;
}

.form-item:has(.field-error) {
  .fw-select {
    box-shadow: 0 0 0 2px var(--color-red-300);
  }

  .input-container {
    box-shadow: 0 0 0 2px var(--color-red-300);
  }
}

@media only screen and (min-width: 768px) {
  .report-overview .report-section,
  .report-overview .section {
    padding-left: 244px;
    padding-right: 244px;
    padding-top: 40px;
  }

  .report-overview .wrapper {
    display: flex;
    flex-direction: column;
  }

  .report-overview .report-section {
    display: flex;
    gap: 20px;
  }

  .report-overview .section {
    background-color: var(--color-light-500);
  }

  .report-overview .product-name {
    font-size: 32px;
  }

  .report-overview .info-container {
    align-items: center;
  }

  .report-overview .row {
    padding: 20px;
  }

  .report-overview .statistic-content {
    padding: 12px 16px;
  }

  .report-overview .images {
    flex: 2;
  }
}

@media only screen and (min-width: 1580px) {
  .report-overview .images {
    flex: 2;
  }
  .report-overview .details {
    flex: 2;
  }
}
