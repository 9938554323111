.input-prefix {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 10px 8px 10px 8px;

  background-color: var(--color-light-400);
}

.input-prefix + .input {
  padding-left: 36px;
}

.input {
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
}

.input:disabled {
  background-color: var(--color-light-300);
  color: var(--color-dark-500);
}

.input-container {
  position: relative;
}

.input-container svg {
  position: absolute;
  top: 8px;
  right: 12px;
  pointer-events: none;
}

input,
select,
textarea {
  border: none;
  outline: none;
  appearance: none;
}

select.empty,
select option.placeholder {
  color: var(--color-light-100);
}

select option:not(.placeholder) {
  color: var(--color-dark-500);
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: 0 0 0 2px var(--color-orange-500);
}

input::placeholder {
  color: var(--color-light-100);
}

select {
  -webkit-appearance: none;
}
